:root {
  --bs-primary-rgb: 166, 227, 161;
  --bs-dark-rgb: 30, 30, 46;
  --bs-black-rgb: 24, 24, 37;
  --bs-btn-bg: 166, 227, 161;

}

h4 {
  font-family: $anton-pro-font-family;
}

ul {
  font-family: $oswald-font-family;
}

p {
  font-family: $roboto-condensed-font-family;
}

.text-sm {
  font-size: 0.875rem;
}


.responsive-iframe {
	min-height: 50vh;
	max-width: 100%;
	padding-top: 56.25%;
	position: relative;
	width: 100%;
}

.responsive-iframe iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
