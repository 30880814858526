@import "../abstracts";

.card-main {
  :hover {
    @include scale(1.1);
    transition: all 0.3s ease-in-out;
  }
}

.rating-pill {
  top: 0.25rem;
  right: 0.25rem;
  z-index: 10;
}

.rating-pill-detail-page {
  top: 0.75rem;
  right: 1.125rem;
  font-size: .875rem;
  z-index: 10;
}
