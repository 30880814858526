@import "../abstracts";

.banner-image-wrapper {
  height: auto;

  @include min-break-point(mobile-phone) {
    height: 100%;
  }
}

.banner-wrapper {
  @include min-break-point(mobile-phone) {
    height: auto;
  }

  @include min-break-point(tablet-landscape) {
    height: 30.75rem;
  }
  // @include min-break-point(larger-desktop) {
  //   height: 32.75rem;
  // }

  // * ==== gradient overlay over banner image ===
  .dark-overlay {
    background: linear-gradient(to top, $dark-opaque-overlay, $transparent);
    bottom: 0;
    left: 0;
    right: 0%;
    top: 0%;
    z-index: 5;

    @include min-break-point(tablet-landscape) {
      background: linear-gradient(to right, $dark-opaque-overlay, $transparent);
      right: 0%;
      top: 0%;
      z-index: 5;
    }
  }

  // * ==== better positioning of banner image =====
  .banner-image {
    object-fit: cover;

    @include min-break-point(larger-desktop) {
      transform: translate(0, -7%);
    }
  }

  // * ==== better positioning of movie details =====
  .movie-details {
    color: $grey-100;
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 5%;
    z-index: 10;

    @include min-break-point(mobile-phone) {
      bottom: 4%;
      font-size: 0.6rem;
      text-align: left;
      left: 5%;
      position: absolute;
      width: 46%;
    }

    @include min-break-point(tablet-landscape) {
      bottom: 0;
      font-size: 0.8rem;
    }
    @include min-break-point(larger-desktop) {
      bottom: 0;
      font-size: 0.9rem;
      left: 7%;
      width: 30%;
    }
  }

  .genre-items li::before {
    content: "|";
    padding: 0 0.75rem;
  }

  .genre-items li:first-child::before {
    content: "";
    padding: 0;
  }
}
