@font-face {
  font-family: "Anton";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/Anton/Anton-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 100;
  src: url("../../fonts/Oswald/Oswald-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/Oswald/Oswald-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/Oswald/Oswald-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 500;
  src: url("../../fonts/Oswald/Oswald-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/Oswald/Oswald-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "Roboto Condensed";
  font-style: italic;
  font-weight: 400;
  src: url("../../fonts/Roboto_Condensed/RobotoCondensed-Italic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Roboto Condensed";
  font-style: italic;
  font-weight: 700;
  src: url("../../fonts/Roboto_Condensed/RobotoCondensed-BoldItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/Roboto_Condensed/RobotoCondensed-Light.ttf")
    format("truetype");
}

@font-face {
  font-family: "Roboto Condensed";
  font-style: italic;
  font-weight: 300;
  src: url("../../fonts/Roboto_Condensed/RobotoCondensed-LightItalic.ttf")
    format("truetype");
}
